import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PageHeader from "../../components/pageheader"
import Padding from "../../components/padding"
import styled from "styled-components"
import Layout from "../../components/layout"
import Stappen from "../../components/stappen"
import Stappen2 from "../../components/stappen2"
import SEO from "../../components/seo"
import { device } from "../../queries"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleRight,faDotCircle, faCheck } from "@fortawesome/free-solid-svg-icons"
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import Img from "gatsby-image"


const Margin = styled.div`
  height: 60px;
`

const Styledcrumbs = styled(Breadcrumbs)`
    .MuiBreadcrumbs-separator{
        font-weight: 600;
    }
`
const Crumblink = styled(Link)`
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    color: var(--blue-primary);
    transition: all 0.25s cubic-bezier(.44,0,.17,1);
    &:hover{
        text-decoration: none;
        color: var(--blue-primary-h); 
    }
    &[aria-current="page"]{
        color: rgba(100,100,100);
        cursor: text;
        
        &:hover{
          

        }
    }
`

const Maincontent = styled.div`
    margin: 0 auto;
    max-width: 1200px;
    padding-top: 60px;
`

const Paragraph = styled.p`
    padding: 10px 0;
`

const Itemlist = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    margin: 10px 0 0 0;
    li{
        font-weight: 400;
        width: 100%;
        padding: 5px 0;
        @media ${device.tablet}{
            width: 100%;
        }
    }
`

const StyledLiIcon = styled(FontAwesomeIcon)`
    color: var(--green-primary);
`

const Bigsub = styled.h2`
    font-weight: 700;
    font-size: 28px;
    color: var(--green-text);
`

const Subheader = styled.h2`
    font-weight: 700;
    font-size: 20px;
    color: var(--green-text);
`

const Containerpad = styled.div`
`
const Vertpad = styled.div`
    padding-bottom: 50px;
    @media ${device.laptopL}{
        padding: 0 20px 60px 20px;
    }

    @media ${device.mobileL}{
        padding: 0 15px 60px 15px;
    }
`
const SmallVertpad = styled.div`
    padding-bottom: 30px;
    @media ${device.laptopL}{
        padding: 0 15px 30px 15px;
    }

    @media ${device.mobileL}{
        padding: 0 15px 30px 15px;
    }
`


const Flexdivider = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    &>*{
        width: 48%;
        @media ${device.tablet}{
        width: 100%;
        &:last-of-type{
            padding-bottom: 0;
        }
        }

    }
    @media ${device.tablet}{
        flex-direction: column;
    }

`

const Checkgrid = styled.div`
    display: inline-grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    gap: 15px 20px;
    padding: 10px 0;
    padding-top: 30px;
    @media ${device.tablet}{
        grid-template-columns: 100%;
    }


`
const Griditem = styled.div`
    display: flex;
    align-items: start;
    p{
        padding: 4px 10px;
    }

`

const Sbr = styled.br`

`

const Icoon = styled(FontAwesomeIcon)`
    font-size: 25px;
    display: inline-block;
    color: var(--green-primary);
`
function handleClick(event) {
    console.info("You clicked a breadcrumb.")
}



const ReparatieSpecialist = () => (
    
  <Layout>
    <SEO title="Vactures" />
    <PageHeader title="Vacatures">
    <Styledcrumbs aria-label="breadcrumb">
        <Crumblink href="/" onClick={handleClick}>
            Home
          </Crumblink>
          <Crumblink href="/informatie" onClick={handleClick}>
            Informatie
          </Crumblink>
          <Crumblink href="/informatie/vacatures" onClick={handleClick}>
            Vacatures
          </Crumblink>
          <Crumblink aria-current="page" href="/informatie/vacatures/Hardware Specialist" onClick={handleClick}>
            Hardware Specialist
          </Crumblink>
        </Styledcrumbs>
    </PageHeader>
        <Maincontent>
            <Containerpad>
                <Vertpad>
                    <Paragraph>
                    <Bigsub>Vacature Lead IT Hardware Specialist</Bigsub>
                    </Paragraph>
                    <SmallVertpad/>
                
                    
                    <Paragraph>
                    Ben jij op zoek naar een leuke en uitdagende baan? Ben je nauwkeurig en een enthousiast persoon die uitdaging vindt in het repareren van smartphones en ICT apparatuur? Dan zijn wij op zoek naar jou!

                    </Paragraph>
                    <Vertpad/>
                    <Subheader>Wie zijn wij?</Subheader>
                    <Paragraph>
                    Wij zijn een groeiende organisatie, gespecialiseerd in smartphones en ICT- apparatuur. Wij verzorgen inkoop, reparatie en verkoop van smartphones  ICT hardware met de focus op security, circulariteit en milieuvriendelijkheid.<br/><br/>
Voor de afdeling revisie zijn wij op zoek naar een toegewijde hardware specialist die als “meewerkend voorman” verantwoordelijk wordt voor de coördinatie en ontwikkeling van de afdeling. De reparatie van ICT apparatuur en smartphones kent voor jou geen geheimen en samen met de collega’s van de afdeling vorm jij het technische “hart” van de organisatie. Je komt te werken in een gezellig en informeel team waar veel ruimte is voor verdere ontplooiing.   
                    </Paragraph>
                    <Vertpad/>
                    <Subheader>Wat ga je doen?</Subheader>
                    <Paragraph>
                    Als Lead IT Hardware Specialist ben jij de persoon die samen met de collega’s van de afdeling smartphones, iPads en laptops controleert en repareert. Je voert ‘quality checks’ uit, identificeert defecten en voert de reparaties uit. Je verricht licht administratieve werkzaamheden voor de registratie in het volgsysteem en kwaliteitsborging.
                    <br/><br/>
                    Daarnaast coördineer jij het revisieteam en alle (logistieke) taken en werkzaamheden. Jij hebt het overzicht van het gehele proces; vanaf intake tot en met het verkoop-gereed maken van alle apparatuur.
                    <br/><br/>
                    Je hebt het overzicht van alle devices in het reparatieproces en je beheert inkoop en voorraden van de spare-parts. Je communiceert met alle afdelingen over de voortgang en knelpunten.


                    </Paragraph>
                    <Vertpad/>
                    <Subheader>Wie zoeken wij?</Subheader>
                    <Paragraph>
                    Je hebt veel interesse in en kennis van de werking van IT hardware en dan met name laptops, en smartphones (apple en android). Je bent in staat om van deze devices onderdelen te vervangen en defecten te repareren.
                    
                   </Paragraph>
                   <SmallVertpad/>
                   <Paragraph>
                
                   <b>De kennis en vaardigheden die wij daarbij zoeken:</b>
                        <Itemlist>
                                    <li><StyledLiIcon icon={faDotCircle} /> Je hebt ervaring met vergelijkbare werkzaamheden en/of een vergelijkbare functie</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Minimaal Mbo niveau 3-4 werk- en denkniveau.</li>
                        </Itemlist>
                   </Paragraph>
                   <SmallVertpad/>
                   <Paragraph>
                
                   <b>De eigenschappen die wij op prijs stellen:</b>
                        <Itemlist>
                                    <li><StyledLiIcon icon={faDotCircle} /> Je bent gestructureerd en kunt volgens procedures werken</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Je bent accuraat, werkt nauwkeurig en voorzichtig</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Je bent proactief en probleemoplossend en komt met verbeteringsideeën</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Je bent gezellig en legt gemakkelijk contacten</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Je bent een voortrekker en in staat om anderen te motiveren;</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Je bent flexibel en hebt geen 9 tot 5 mentaliteit.</li>
                        </Itemlist>
                   </Paragraph>
                   <SmallVertpad/>
                    <Paragraph>
                    <b>Wij bieden:</b>
                        <Itemlist>
                                    <li><StyledLiIcon icon={faDotCircle} /> Werken bij een "groen" en groeiend bedrijf met volop ontwikkelingsmogelijkheden.</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> De mogelijkheid om met eigen inzicht en vrijheid de afdeling te ontwikkelen.</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Fijne collega's en een gezellige en informele werksfeer.</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Een marktconform salaris afhankelijk van expertise en ervaring.</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Reiskostenvergoeding.</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Laptop.</li>
                        </Itemlist>
                    </Paragraph>
                </Vertpad>
                <Vertpad>
                    <Paragraph>
                        Soort dienstverband: Full time, bepaalde tijd.
                        <br />
                        <br />
                        Ben je geïnteresseerd? Stuur dan een korte sollicitatiebrief en CV naar J.Goossens@GreenDigital.nl. 
                        
                     
                    </Paragraph>
                    <Paragraph/>
                    <Paragraph/>
                    <Paragraph>



                    </Paragraph>
                
                </Vertpad>
                
            </Containerpad>
        </Maincontent>
    <Padding/>
  </Layout> 
)

export default ReparatieSpecialist


